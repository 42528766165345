<template>
	<div>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Nombre</b-th>
						<b-th>Fechas</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, i) in rows" :key="row.id">
						<b-td>
							<strong>{{ row.season }}</strong>
							<FeatherIcon
								icon="EditIcon"
								size="20"
								class="cursor-pointer text-warning ml-1"
								@click="
									(creationModal.active = true),
										(creationModal.editing = true),
										(creationModal.info = row)
								"
							/>
						</b-td>
						<b-td class="text-center">
							<div class="d-flex align-items-center justify-content-center">
								<div class="d-flex align-items-center justify-content-center">
									<b-form-group label="Inicio" class="mx-1" style="pointer-events: none">
										<flat-pickr v-model="row.start" class="form-control" />
									</b-form-group>
									<b-form-group label="Final" class="mx-1">
										<flat-pickr
											v-model="row.end"
											class="form-control"
											:class="i != 0 ? '' : 'bg-white'"
											:config="{ minDate: i == 0 ? row.end : null }"
											@on-change="(creationModal.info = row), edit()"
										/>
										<!-- @input="changeDateEnd" -->
									</b-form-group>
								</div>
								<!-- <FeatherIcon
									v-if="i == 0 && row.active_season == 1"
									icon="EditIcon"
									size="20"
									class="cursor-pointer text-warning"
									@click="(creationModal.editing = true), (creationModal.info = row), edit()"
								/> -->
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2">
							<p class="mb-0">No hay registros por mostrar</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-modal centered :title="modalTitle" v-model="creationModal.active" @hidden="handleHidden">
			<b-overlay :show="creationModal.loading">
				<b-form-group label="Nombre">
					<b-form-input v-model="creationModal.name" />
				</b-form-group>
				<b-form-group label="Inicio" v-if="!creationModal.editing">
					<b-form-datepicker locale="es" v-model="creationModal.start" />
				</b-form-group>
				<b-form-group label="Fin" v-if="!creationModal.editing">
					<b-form-datepicker
						locale="es"
						:min="rows[0] ? rows[0].start : dateNow"
						v-model="creationModal.end"
					/>
				</b-form-group>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="info" :disabled="!creationModal.name" @click="editName" v-if="creationModal.editing"
					>Editar</b-button
				>
				<b-button
					variant="success"
					:disabled="!creationModal.name || !creationModal.start"
					@click="create"
					v-else
					>Guardar</b-button
				>
			</template>
		</b-modal>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/settings.service";

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: {
			active: false,
			loading: false,
			editing: false,
			name: "",
			start: null,
			end: null,
			info: {},
		},
		dateNow: new Date(),
	}),
	computed: {
		modalTitle() {
			return this.creationModal.editing ? "Actualizar nombre de temporada" : "Crear nueva temporada";
		},
	},
	methods: {
		async getRows() {
			this.isLoading = true;
			this.rows = []
			const { data } = await preparacionService.getSeasons({ status: null });
			const canCreate = data.some((season) => season.active_season != 0);
			if (canCreate) {
				this.$emit("blocked");
			}
			this.rows = data;
			this.isLoading = false;
		},
		handleHidden() {
			this.creationModal = {
				active: false,
				loading: false,
				editing: false,
				name: "",
				start: null,
				end: null,
				info: {},
			};
			this.$emit("actionDone");
		},
		async create() {
			this.creationModal.loading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se creara la temporada ${this.creationModal.name}`,
			});
			if (!isConfirmed) {
				this.creationModal.loading = false;
				return;
			}
			await preparacionService.createSeason({
				name: this.creationModal.name,
				start_date: this.creationModal.start,
				end_date: this.creationModal.end,
			});
			this.creationModal.loading = false;
			this.handleHidden();
			this.getRows();
		},
		async editName() {
			this.creationModal.loading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se modificara el nombre de la temporada ${this.creationModal.info.season}`,
			});
			if (!isConfirmed) {
				this.creationModal.loading = false;
				return;
			}
			await preparacionService.editSeasonName({
				season_id: this.creationModal.info.id,
				name: this.creationModal.name,
			});
			this.creationModal.loading = false;
			this.handleHidden();
			this.getRows();
		},
		async edit() {
			this.isLoading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se modificara la temporada ${this.creationModal.info.season}`,
			});
			if (!isConfirmed) {
				this.isLoading = false;
				this.getRows();
				return;
			}
			await preparacionService.editSeason({
				season_id: this.creationModal.info.id,
				end_date: this.creationModal.info.end,
			});
			this.isLoading = false;
			this.handleHidden();
			this.getRows();
		},

		changeDateEnd() {
			console.log("./.././");
		},
		testt() {
			console.log("-0-0-0-0-");
		},
	},
	created() {
		this.getRows();
	},
	watch: {
		action(val) {
			if (val == "create-season") {
				this.creationModal.active = !this.creationModal.active;
			}
		},
	},
};
</script>
